import { AButton } from '@allvit/allvit-toolkit';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    List,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

export const RemoveBookModal = ({
    confirmDialogOpen,
    closeConfirmDialog,
    removeBookHandler,
    state,
}: {
    confirmDialogOpen: boolean;
    closeConfirmDialog: () => void;
    removeBookHandler: () => void;
    state: 'idle' | 'loading' | 'submitting';
}) => {
    const theme = useTheme();

    const desktopView = useMediaQuery(theme.breakpoints.up('md'));

    return desktopView ? (
        <Dialog
            sx={{ '& .MuiDialog-paper': { boxShadow: 'none', minWidth: '600px', p: 2 } }}
            maxWidth="xs"
            open={confirmDialogOpen}
            onClose={closeConfirmDialog}
        >
            <DialogTitle sx={{ mt: 2 }}>Fjern bok fra bokhylla</DialogTitle>
            <DialogContent>
                Notater, markeringer og bokmerker blir lagret, slik at de er tilgjengelige dersom du legger boka tilbake
                i bokhylla igjen.
            </DialogContent>
            <DialogActions>
                <AButton autoFocus onClick={closeConfirmDialog} variant="secondary">
                    Avbryt
                </AButton>

                {state === 'idle' ? (
                    <AButton
                        onClick={async () => {
                            await removeBookHandler();
                            closeConfirmDialog();
                        }}
                        variant="primary"
                        data-cy="cy-remove-book-from-bookshelf-accept"
                    >
                        Fjern
                    </AButton>
                ) : (
                    <CircularProgress sx={{ color: theme.palette.cta.main }} size={18} />
                )}
            </DialogActions>
        </Dialog>
    ) : (
        <Drawer
            open={confirmDialogOpen}
            anchor="bottom"
            transitionDuration={500}
            onClose={closeConfirmDialog}
            sx={{ '.MuiPaper-root': { py: 8, px: 4, borderRadius: theme.radius.allXLargeTop } }}
        >
            <Typography variant="h6">Fjern bok fra bokhylle</Typography>
            <List sx={{ ml: 4, py: 4 }}>
                Notater, markeringer og bokmerker blir lagret, slik at de er tilgjengelige dersom du legger boka tilbake
                i bokhylla igjen.
            </List>
            {state === 'idle' ? (
                <AButton
                    size="large"
                    center
                    fullWidth
                    onClick={async () => {
                        await removeBookHandler();
                        closeConfirmDialog();
                    }}
                    variant="primary"
                    sx={{ mb: 2 }}
                >
                    Fjern
                </AButton>
            ) : (
                <CircularProgress sx={{ color: theme.palette.cta.main }} size={18} />
            )}
            <AButton size="large" center fullWidth autoFocus onClick={closeConfirmDialog} variant="secondary">
                Avbryt
            </AButton>
        </Drawer>
    );
};
